import { createStore } from 'vuex';
import { getLeaderboardData, getSubjectsData } from '@/services/service';

interface State {
  students: any[];
  subjects: any[];
  totalStudents: number;
  pageSize: number;
  currentPage: number;
}

interface Subject {
  id: number;
  name: string;
}

export default createStore<State>({
  state: {
    students: [],
    subjects: [],
    totalStudents: 0,
    pageSize: 20,
    currentPage: 1
  },
  mutations: {
    SET_STUDENTS(state, students) {
      state.students = students;
    },
    SET_SUBJECTS(state, subjects) {
      state.subjects = subjects;
    },
    SET_TOTAL_STUDENTS(state, total) {
      state.totalStudents = total;
    },
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    }
  },
  actions: {
    async fetchSubjects({ commit, state }) {
      if (state.subjects.length === 0) {
        try {
          const allSubjects = await getSubjectsData();

          const allowedSubjects: string[] = ['English Language', 'Basic Science', 'Mathematics'];

          const filteredSubjects = allSubjects.filter((subject: Subject) =>
              allowedSubjects.includes(subject.name)
          );

          commit('SET_SUBJECTS', filteredSubjects);
        } catch (error) {
          console.error('Error fetching subjects:', error);
          throw error;
        }
      }
    },
    async fetchStudents({ commit, state }, { query, subject_id, start_time, page }) {
      try {
        const response = await getLeaderboardData({ query: query, subject_id: subject_id, start_time: start_time, page: page, limit: state.pageSize });
        commit('SET_STUDENTS', response);
        commit('SET_TOTAL_STUDENTS', 20000);
        commit('SET_CURRENT_PAGE', page);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
        throw error;
      }
    }
  },
  getters: {
    totalPages: (state) => Math.ceil(state.totalStudents / state.pageSize)
  }
});