import { ref, onMounted } from 'vue'

export function useTimezone() {
    const timezone = ref('')

    function detectTimezone() {
        try {
            return Intl.DateTimeFormat().resolvedOptions().timeZone
        } catch (error) {
            console.error('Error detecting timezone:', error)
            return null
        }
    }


    timezone.value = detectTimezone() || 'Africa/Lagos'

    return { timezone }
}