const APP_NAME = "Gradely-Leaderboard";
const API_VERSION = "v2.1";
const BASE_API_URL = "https://api.gradely.co/v2.1";
const OLD_BASE_URL = "https://api.gradely.ng/";
const environment = "environment";
const LANDING_BASE_URL= "https://app.gradely.co";


const APP_ENVIRONMENT = "development";
const APP_VERSION = "1.0";
const SENTRY_DSN =
  "https://e6c616ac7044480091801775c959269d@o1112138.ingest.sentry.io/6141562";

export {
  environment,
  OLD_BASE_URL,
  BASE_API_URL,
  APP_NAME,
  API_VERSION,
  APP_ENVIRONMENT,
  APP_VERSION,
  SENTRY_DSN,
  LANDING_BASE_URL,
};
