import { createRouter, createWebHistory, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useTimezone } from '@/services/timezone'

const routes = [
  {
    path: '/:region(uk|ng)?',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "leaderboard" */ '@/views/LeaderBoardView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
]

function getRoutePrefixFromTimezone(timezone: string): string {
  if (timezone.startsWith('Europe/')) return 'uk'
  if (timezone.startsWith('Africa/')) return 'ng'
  return 'ng' // Default route prefix
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const { timezone } = useTimezone()
  const userTimezone = timezone.value
  const expectedPrefix = getRoutePrefixFromTimezone(userTimezone)

  if (!to.params.region && to.path === '/') {
    next(`/${expectedPrefix}`)
  } else {
    next()
  }
})

export default router