<template>
  <div id="app" class="min-h-screen bg-gray-100">
    <main class="mx-auto">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');


body {
  font-family: 'Noto Sans', sans-serif;
  padding-bottom: 0 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

</style>
