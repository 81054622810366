import axios from 'axios';
import { API_VERSION, BASE_API_URL, OLD_BASE_URL } from "../../env";
import { useTimezone } from "@/services/timezone";
import {useRouter} from "vue-router";

const { timezone } = useTimezone();

const client = axios.create({
    baseURL: BASE_API_URL,
});

const getTimezoneFromPath = (path: string): string => {
    if (path.startsWith('/uk')) {
        return 'Europe';
    } else if (path.startsWith('/ng')) {
        return 'Africa';
    }
    return 'Africa';
};

export const setupInterceptors = (router: any) => {
    client.interceptors.request.use(config => {
        const currentPath = router.currentRoute.value.path;
        config.headers['Timezone'] = getTimezoneFromPath(currentPath);
        return config;
    });
};

interface Filters {
    subject_id: number;
    query: string | null;
    start_time: string | null;
    page: number | null;
    limit: number | null;
}

export const getLeaderboardData = async (filters: Filters) => {
    const response = await client.get("/report/global-mastery-leaderboard", { params: filters });
    return response.data.data;
};

export const getSubjectsData = async () => {
    const response = await axios.get(OLD_BASE_URL+"v2/general/subject");
    return response.data.data;
};
